import React from 'react'
import OurMantraJson from "../textFiles/ourMantra.json"
import Accordion from 'react-bootstrap/Accordion';

export default function Mantra() {

    const mantras = []
    var counter = 0
    OurMantraJson.entries.forEach((entry) => {

        mantras.push(
            <Accordion.Item eventKey={"" + counter} classname="col-xs-12">
                <Accordion.Header>{entry.mantra}</Accordion.Header>
                <Accordion.Body>{entry.body}</Accordion.Body>
            </Accordion.Item>          
        )
        counter+=1;
    })

    return(

        <div className="mantra">
            <div className="container mantra-title">
                <div className="row ">
                    <div className="col-sx-12 text-center">
                        <span className="mantras-heading">Mantras</span>
                    </div>
                </div>
            </div>
            <div className="container ourMissionLetter">
                <Accordion defaultActiveKey="0" className="row gy-2">
                    {mantras}
                </Accordion>
            </div>
        </div>
    )
}