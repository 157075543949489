import React from 'react'
import AboutUsJson from '../textFiles/aboutUs.json'

export default function About() {

    const membersSectionHTML = []
    AboutUsJson.members.forEach((member, index) => {

        membersSectionHTML.push(
            <div className="col-sx-12 col-md-4 memberCard">
                <img className="avatarImg img-responsive" src={member.avatarPath} alt={member.name}></img>
                <div className="cardInverse">
                    <p className="memberName">{member.name}</p>
                    <p className="memberBio">{member.bio}</p>
                </div>
            </div>
        )
    })

    return(

        <div className="about-us pb-5">
            <div className="container about-title">
                <div className="row ">
                    <div className="col-sx-12 text-center">
                        <span className="about-heading">About Us</span>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row aboutUsIntro">

                    <p>{AboutUsJson.intro}</p>
                </div>  
                <div className="row gy-3 align-items-center justify-content-around aboutUsBody">

                    {membersSectionHTML}
                </div>  
            </div>
            <div id="mantras-anchor"></div>
        </div>
    )
}