import React from 'react'
import SexyDog from '../images/logos/logo.png'

export default function Hero() {

    return (

		<div className="container-fluid hero-background" id="home">
			<div className="row justify-content-center m-0">
				<img className="col-sx-12 hero-img" src={SexyDog}></img>
			</div>
			<div id="games-anchor"></div>
		</div>
    )
}
