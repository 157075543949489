import React from 'react'
import tapeTop from '../images/tape-top.png'
import tapeBottom from '../images/tape-bottom.png'

export default function Games() {

    return(
        <div className="ourGamesSection">
            
            <div className="container mantra-title">
                <div className="row">
                    <div className="col-sx-12 text-center">
                        <span className="games-heading">Upcoming Titles</span>
                    </div>
                </div>
            </div>
            <div class="container">
                <div className="row gy-3 align-items-center justify-content-around ourGamesBody">
                    <div className="col-sm-12 col-lg-5 projectCard card-b">
                        <img className="test-img tape-top" src={tapeTop}></img>
                        <img className="test-img tape-bottom" src={tapeBottom}></img>
                        <div className="card-text">
                            <span class="projectTitle">Captain</span>
                            <br/>
                            <span class="projectTitle">Kibbles</span>
                            <br/>
                            <span className="project-description">Retro-Inspired Shooter</span>
                        </div>
                    </div>              
                    <div className="col-sm-12 col-lg-5 projectCard card-a">
                        <img className="test-img tape-top" src={tapeTop}></img>
                        <img className="test-img tape-bottom" src={tapeBottom}></img>
                        <div className="card-text">
                            <span className="projectTitle">Steelweaver</span>
                            <br/>
                            <span className="projectTitle">Legacy</span>
                            <br/>
                            <span className="project-description">Casual Crafting Sim</span>
                        </div>
                    </div>
                </div>
            </div>
            <div id="about-anchor"></div>
        </div>
    )
}