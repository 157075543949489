import React from 'react';
import Head from './js/head';
import TopNav from './js/topnav';
import Hero from './js/hero';
import Mantra from './js/mantra'
import Games from './js/games';
import About from './js/about'
import Footer from './js/footer'

import './css/style.css';
import './css/topnav.css';
import './css/hero.css';
import './css/games.css';
import './css/about.css';
import './css/mantra.css';
import './css/footer.css';

export default function App() {

    return (
        <>
			<Head />
				<TopNav />
				<Hero />
				<Games />
				<About />
				<Mantra />
				<Footer />
        </>
    );
}
