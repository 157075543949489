import React from 'react'

export default function TopNav() {

    return (
		<div className="topnav">
			<div className="container">
				<div className="row">
					<div className="col-sx-12">
						<a href="#home" className="brand-name">Rebel Pooch Studios</a>
						<a href="#mantras-anchor" id="topnavMission" className="topnav-item">Mantras</a>
						<a href="#about-anchor" id="topnavAbout" className="topnav-item">About</a>
						<a href="#games-anchor" id="topnavGames" className="topnav-item">Games</a>
						{/* <a href="#" className="icon">🍔</a> */}
					</div>
				</div>
			</div>
		</div>
    );
}